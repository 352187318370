import styles from './FooterComponent.module.css';

import Discord from './../../Assets/Media/Discord.svg';
import MagicEden from './../../Assets/Media/MagicEden.svg';
import Twitter from './../../Assets/Media/Twitter.svg';

export const FooterComponent = () => {

    const discordLink = () => {
        window.open('http://discord.gg/bejoe', '_blank');
    }

    const twitterLink = () => {
        window.open('https://twitter.com/bejoenft', '_blank');
    }

    const magicEdenLink = () => {
        window.open('https://magiceden.io/marketplace/bejoe', '_blank');

    }

    return (
        <div>
            <div className={styles.footerArea}>
                <div className={styles.copyRight}>
                    © BeJoe. All Rights Reserved.
                </div>
                <div className={styles.socialLink}>
                    <div>Social Links:</div>
                    <img onClick={discordLink} src={Discord} />
                    <img onClick={twitterLink} src={Twitter} />
                    <img onClick={magicEdenLink} src={MagicEden} />
                </div>
            </div>
            <div className={styles.heightSpace} ></div>
        </div>
    )
}