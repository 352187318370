import React from 'react';
import styles from './App.module.css';
import { HeaderComponent } from './Components/Header/HeaderComponent';
import Background from './Assets/Media/Background.svg'
import { SummaryReportComponent } from './Components/SummaryReport/SummaryReportComponent';
import { ProjectExpensesComponent } from './Components/ProjectExpenses/ProjectExpensesComponent';
import { WorkLogComponent } from './Components/WorkLog/WorkLogComponent';
import { WorkProgressComponent } from './Components/WorkProgress/WorkProgressComponent';
import { FooterComponent } from './Components/Footer/FooterComponent';
function App() {
  return (
    <div className={styles.App} >
      <HeaderComponent />
      <WorkLogComponent />
      <WorkProgressComponent />
      <FooterComponent />
  
    </div>
  );
}

export default App;




// style={{ backgroundImage: `url(${Background})` }}