import styles from './WorkLogComponent.module.css';
import { CalendarDay } from '../../Types';
import vector from './../../Assets/Media/Vector.svg';
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import ConvertDate from '../Shared/ConvertDate';
import { DropDown, DropDownOption } from '../Shared/DropDown/DropDown';
import stylesDropDown from './../Shared/DropDown/DropDown.module.css';
import { Tooltip } from 'react-tooltip'

const createCalendar = (year: number): CalendarDay[] => {
    const months = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    let calendar: CalendarDay[] = [];


    for (let month = 0; month < 12; month++) {
        for (let day = 1; day <= months[month]; day++) {
            calendar.push({
                date: '',
                count: 0,
                disabled: false,
                day: day,
                month: month + 1, // Months are 1-indexed
                tooltip: '',
                year: year,
                key: null
            });
        }
    }

    return calendar;
}

type Years = {
    year: number;
}

type WorkLogComponentProps = {

}

export const WorkLogComponent = () => {


    const [finalData, setData] = useState<CalendarDay[]>([]);
    const [contributionsCount, setContributionsCount] = useState<number>(0);
    const [yearFilter, setyearFilterData] = useState<number>(new Date().getFullYear());
    const [defaultYearFilter, setDefaultYearFilterData] = useState<DropDownOption>();

    const [lastUpdate, setLastUpdate] = useState<string>('');

    const [monthFilter, setmonthFilterData] = useState<number>(0);
    const handleSelectMonthFilter = (option: DropDownOption) => {
        setmonthFilterData(Number(option.value));
    }


    const [dropDownYears, setDropDownYears] = useState<DropDownOption[]>([]);
    const handleSelectYearFilter = (option: DropDownOption) => {
        setyearFilterData(Number(option.value));
    }


    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const dropDownMonthsWide: DropDownOption[] = [
        { value: '0', label: 'All Months' },
        { value: '1', label: 'Jan' },
        { value: '2', label: 'Feb' },
        { value: '3', label: 'Mar' },
        { value: '4', label: 'Apr' },
        { value: '5', label: 'May' },
        { value: '6', label: 'Jun' },
        { value: '7', label: 'Jul' },
        { value: '8', label: 'Aug' },
        { value: '9', label: 'Sep' },
        { value: '10', label: 'Oct' },
        { value: '11', label: 'Nov' },
        { value: '12', label: 'Dec' },
    ];
    const dropDownMonthsMobile: DropDownOption[] = [

        { value: '1', label: 'Jan' },
        { value: '2', label: 'Feb' },
        { value: '3', label: 'Mar' },
        { value: '4', label: 'Apr' },
        { value: '5', label: 'May' },
        { value: '6', label: 'Jun' },
        { value: '7', label: 'Jul' },
        { value: '8', label: 'Aug' },
        { value: '9', label: 'Sep' },
        { value: '10', label: 'Oct' },
        { value: '11', label: 'Nov' },
        { value: '12', label: 'Dec' },
    ];
    const [dropDownMonths, setDropDownMonths] = useState<DropDownOption[]>(dropDownMonthsWide)





    // Get Years, set default year filter
    useEffect(() => {
        fetch(`https://api.track.bejoe.io/api/progress/yearactivity/`)
            .then(res => res.json())
            .then((data: Years[]) => {
                data.sort((a, b) => a.year - b.year);
                let yearsDropDownOption: DropDownOption[] = [];
                data.forEach(i => {
                    const y: DropDownOption = {
                        label: i.year.toString(),
                        value: i.year.toString()
                    }
                    if (i.year == yearFilter) {
                        setDefaultYearFilterData(y);

                    }
                    yearsDropDownOption.push(y);
                });

                setDropDownYears(yearsDropDownOption);

            });
    }, []);

    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

    useEffect(() => {


        if (isMobile) {

            var currentMonth = new Date().getMonth() + 1;
            console.log("🚀 ~ useEffect ~ currentMonth:", currentMonth)

            setmonthFilterData(currentMonth);
            setDropDownMonths(dropDownMonthsMobile);

        } else {
            setmonthFilterData(0);
            setDropDownMonths(dropDownMonthsWide);

        }
    }, [isMobile]);

    useEffect(() => {
        fetch(`https://api.track.bejoe.io/api/progress/contribution/`)
            .then(res => res.json())
            .then((data: CalendarDay[]) => {

                if (data != null && data != undefined && data.length > 0) {

                    //                     const mock: CalendarDay = {
                    //                         count: 5,
                    //                         date
                    //                             :
                    //                             "2024-02-01",
                    //                         day:
                    //                             1,

                    //                         month
                    //                             :
                    //                             2,
                    //                         year
                    //                             :
                    //                             2024,
                    //                             disabled:false,
                    //                             key:4,
                    //                             tooltip:'ddsd'
                    //                     }

                    // data.push(mock)


                    data.forEach(cal => {
                        let date = cal.date.split('-');
                        cal.year = Number(date[0]);
                        cal.month = Number(date[1]);
                        cal.day = Number(date[2]);
                    });
                    console.log("🚀 ~ .then ~ data:", data)

                    let currentYearData = data.filter(d => d.year == yearFilter);

                    const sum = currentYearData.reduce((total, obj) => total + obj.count, 0);
                    setContributionsCount(sum);

                    if (currentYearData && currentYearData.length > 0) {
                        currentYearData.sort((a, b) => {
                            return new Date(b.date).getTime() - new Date(a.date).getTime();
                        });

                        setLastUpdate(ConvertDate(currentYearData[0].date, false, false));
                    } else {
                        setLastUpdate('');
                    }


                    const calendar = createCalendar(yearFilter);



                    let joinData: CalendarDay[] = []
                    calendar.forEach(item => {
                        var join = data?.find(d => d.month === item.month && d.day === item.day && d.year === item.year);
                        if (join) {
                            const dateString = join.date;
                            item.tooltip = `${join.count} Contributions on ${ConvertDate(dateString, true, false)}`;
                            item.key = join.count == 1 ? 1 : (join.count < 4 && join.count > 1) ? 2 : join.count > 3 ? 3 : 0;
                        }
                        joinData.push(item);
                    });
                    setData(joinData);



                } else {
                    setContributionsCount(0);
                    const calendar = createCalendar(yearFilter);
                    setData(calendar);
                }
            });
    }, [yearFilter]);

    // Month Filter
    useEffect(() => {
        if (finalData.length > 0) {
            const updatedData = finalData.map(item => {

                if (monthFilter == 0) {
                    return { ...item, disabled: false };
                } else {
                    if (item.month !== monthFilter) {
                        return { ...item, disabled: true };
                    } else {
                        return { ...item, disabled: false };
                    }
                }


            });

            setData(updatedData);
        }
    }, [monthFilter]);

    return (
        <div className={styles.workLog}>
            <div className={styles.workLogContainer}>
                <div className={styles.workLogHeader}>

                    <div className={styles.workLogTitle}>
                        <p className={styles.title}>Work Log</p>
                        <p className={styles.subTitle}>Daily work activity of the BeJoe team per year.</p>
                    </div>
                    <div className={styles.workLogFilter}>

                        <DropDown onSelect={handleSelectYearFilter} customClass={stylesDropDown.yearFilterCustom} options={dropDownYears} defaultSelectedItem={defaultYearFilter} />

                        <DropDown onSelect={handleSelectMonthFilter}
                            customClass={stylesDropDown.monthFilterCustom}
                            options={dropDownMonths}
                            defaultSelectedItem={isMobile==true? dropDownMonths[monthFilter-1]: dropDownMonths[0]}
                        />

                    </div>
                </div>
                <div className={styles.workLogCalendar}>
                    <div className={styles.calendarTitle}>
                        <span>{contributionsCount}</span> contributions in {yearFilter} till now
                    </div>

                    <div className={styles.calendarContainer}>
                        <div className={styles.months}>
                            {months.map((month, key) => (
                                <div key={key}>{month}</div>
                            ))}
                            <div key={13}></div>
                        </div>

                        <div className={styles.calendar} >
                            {isMobile == false ? (
                                <div className={`${styles.column} ${styles.widthVersionCalendar}`}>
                                    {finalData.map((day, index) => {

                                        let className = '';

                                        if (day.disabled == true) {
                                            className = styles.disableDay;
                                        } else if (day.key == 1) {
                                            className = styles.lowPriority;
                                        } else if (day.key == 2) {
                                            className = styles.mediumPriority;
                                        } else if (day.key == 3) {
                                            className = styles.highPriority;
                                        }

                                        return (
                                            // <BootstrapTooltip key={index} title={day.tooltip} placement="right-end" arrow >
                                            <div
                                                key={index}
                                                className={`${styles.button} ${className}`}
                                                style={{ fontSize: '10px' }}
                                                data-tooltip-id='my-tooltip'
                                                data-tooltip-content={day.tooltip}
                                                data-tooltip-place="right"
                                                data-tooltip-class-name={styles.tooltip}

                                            >

                                            </div>
                                            // </BootstrapTooltip>
                                        )
                                    })}
                                </div>
                            ) : (
                                <div className={`${styles.column} ${styles.mobileVersionCalendar}`}>
                                    {finalData.filter(d => d.month == monthFilter).map((day, index) => {

                                        let className = '';

                                        if (day.disabled == true) {
                                            className = styles.disableDay;
                                        } else if (day.key == 1) {
                                            className = styles.lowPriority;
                                        } else if (day.key == 2) {
                                            className = styles.mediumPriority;
                                        } else if (day.key == 3) {
                                            className = styles.highPriority;
                                        }

                                        return (
                                            <div key={index} className={`${styles.button} ${className}`} style={{ fontSize: '10px' }}
                                                data-tooltip-id='my-tooltip'
                                                data-tooltip-content={day.tooltip}
                                                data-tooltip-place="right"
                                                data-tooltip-class-name={styles.tooltip} >

                                            </div>
                                        )
                                    })}
                                </div>
                            )}



                        </div>
                    </div>

                    <div className={styles.footer}>

                        <div className={styles.lastUpdate}><img src={vector} /><span>Last Updated:</span> {lastUpdate}</div>


                        <div className={styles.priorityGuid}>
                            <span>Less</span>
                            <div className={styles.buttonPriority} style={{ backgroundColor: '#383838' }}></div>
                            <div className={styles.buttonPriority} style={{ backgroundColor: '#B095FD' }}></div>
                            <div className={styles.buttonPriority} style={{ backgroundColor: '#9673F9' }}></div>
                            <div className={styles.buttonPriority} style={{ backgroundColor: '#6E45E1' }}></div>
                            <span>More</span>
                        </div>
                    </div>
                </div>
            </div>
            <Tooltip id="my-tooltip" />
        </div>
    );
};