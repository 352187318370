import { useEffect, useState } from 'react';
import BrandName from './../../Assets/Media/BrandName.svg'
import styles from './WorkProgressComponent.module.css'
import LinearProgress from '@mui/material/LinearProgress';
import { WorkProgress } from '../../Types';
import EllipsisText from '../Shared/EllipsisText';
import Arrow from './../../Assets/Media/Arrow.svg';
import { WorkProgressDialogComponent } from '../WorkProgressDialog/WorkProgressDialogComponent';
import { useMediaQuery } from 'react-responsive';
import { WorkProgressDialogMobileComponent } from '../WorkProgressDialogMobile/WorkProgressDialogMobileComponent';
import ConvertDate from '../Shared/ConvertDate';
import { DropDown, DropDownOption } from '../Shared/DropDown/DropDown';
import stylesDropDown from './../Shared/DropDown/DropDown.module.css';

export const toTitleCase = (str: string) => {
    return str.toLowerCase().split(' ').map(function (word) {
        return word.replace(word[0], word[0].toUpperCase());
    }).join(' ');
};
export const WorkProgressComponent = () => {


    const [progressData, setData] = useState<WorkProgress[]>([]);
    const [progressDataFilter, setProgressDataFilter] = useState<WorkProgress[]>([]);

    {/* <option value={''}>All</option>
                                    <option value={'in progress'}>In Progress</option>
                                    <option value={'completed'}>Complete</option> */}


    const [statusFilter, setStatusFilter] = useState<number>(0);
    const statusFilterOptions: DropDownOption[] = [
        { value: '0', label: 'All' },
        { value: '1', label: 'In Progress' },
        { value: '2', label: 'Complete' },
    ]
    const handleSelectStatusFilter = (item: DropDownOption) => {
        setStatusFilter(Number(item.value));
    }

    const [productFilter, setProductFilterFilter] = useState<number>(0);
    const productFilterDropdownOptions: DropDownOption[] = [
        { value: '0', label: 'All Products' },
        { value: '1', label: 'Bejoe Products' },
        { value: '2', label: 'Clients Products' },
    ]
    const handleSelectProductFilter = (item: DropDownOption) => {
        setProductFilterFilter(Number(item.value))
    }

    const [sort, setSort] = useState<number>(0);
    const sortFilterDropdownOptions: DropDownOption[] = [
        { value: '0', label: 'Default' },
        { value: '1', label: 'Low to High' },
        { value: '2', label: 'High to Low' },
        { value: '3', label: 'Newest Updates' },
        { value: '4', label: 'Oldest Updates' },
    ];

    const handleSelectSortFilter = (item: DropDownOption) => {
        console.log(item);

        setSort(Number(item.value));
    }


    const [open, setOpen] = useState(false);

    const [dialogWorkProgress, setDialogWorkProgress] = useState<WorkProgress | null>(null)

    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });


    const handleClickOpen = (data: WorkProgress) => {
        setOpen(true);
        setDialogWorkProgress(data);
    };
    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        fetch('https://api.track.bejoe.io/api/progress/progresswork/')
            .then(res => res.json())
            .then((data: WorkProgress[]) => {

                const dd = data.map(d => {
                    return { ...d, dateTime: new Date(d.latest_log_date) }
                });
                setData(dd);

                const statusConvert = data.map(s => {
                    return { ...s, status: s.progress_percentage == 100 ? 'Complete' : 'In Progress' }
                });

                setData(statusConvert);
                setProgressDataFilter(statusConvert);
            });
    }, []);

    useEffect(() => {
        if (progressData.length > 0) {

const sf = statusFilter == 1? 'In Progress':statusFilter == 2?'Complete':'';

            if (productFilter == 0) {
                const updatedData = progressData.filter(p => (statusFilter == 0 || p.status == sf));
                setProgressDataFilter(updatedData);
            }
            if (productFilter == 1) {
                const updatedData = progressData.filter(p => (statusFilter == 0 || p.status == sf) && p.brand_name.toLowerCase() == 'bejoe');
                setProgressDataFilter(updatedData);
            }

            if (productFilter == 2) {
                const updatedData = progressData.filter(p => (statusFilter == 0 || p.status == sf) && p.brand_name.toLowerCase() != 'bejoe');
                setProgressDataFilter(updatedData);
            }
        }
    }, [statusFilter, productFilter]);

    useEffect(() => {
        if (sort == 1) {
            const lowToHigh = [...progressDataFilter].sort((a, b) => a.progress_percentage - b.progress_percentage);
            setProgressDataFilter(lowToHigh);
        }

        if (sort == 2) {
            const highToLow = [...progressDataFilter].sort((a, b) => b.progress_percentage - a.progress_percentage);
            setProgressDataFilter(highToLow);
        }

        if (sort == 3) {
            const newest = [...progressDataFilter].sort((a, b) => new Date(b.latest_log_date).getTime() - new Date(a.latest_log_date).getTime());
            setProgressDataFilter(newest);
        }

        if (sort == 4) {
            const oldest = [...progressDataFilter].sort((a, b) => new Date(a.latest_log_date).getTime() - new Date(b.latest_log_date).getTime());
            setProgressDataFilter(oldest);
        }
    }, [sort]);


    return (
        <div className={styles.workProgress} >
            <div className={styles.workProgressContainer}>
                <div className={styles.workProgressHeader}>

                    <div className={styles.workProgressTitle}>
                        <p className={styles.title}>Work Progress</p>
                        <p className={styles.subTitle}>An open sourced board displaying different phases of work.</p>
                    </div>

                    <div className={styles.workProgressFilter}>
                        <div className={styles.filterMobileVersion}>

                            <DropDown onSelect={handleSelectSortFilter} customClass={`${stylesDropDown.sortFilterMobileCustom}`} options={sortFilterDropdownOptions} defaultSelectedItem={sortFilterDropdownOptions[sort]} />

                        </div>
                        <div className={styles.widthAndMobile}>

                            <DropDown onSelect={handleSelectStatusFilter} customClass={stylesDropDown.statusFilterCustom} options={statusFilterOptions} defaultSelectedItem={statusFilterOptions[statusFilter]} />

                            <div className={styles.spaceInMobile}></div>

                            <DropDown onSelect={handleSelectSortFilter} customClass={`${stylesDropDown.sortFilterCustom} ${stylesDropDown.filterWidthVersion}`} options={sortFilterDropdownOptions} defaultSelectedItem={sortFilterDropdownOptions[sort]} />

                            <DropDown onSelect={handleSelectProductFilter} customClass={stylesDropDown.productFilterCustom} options={productFilterDropdownOptions} defaultSelectedItem={productFilterDropdownOptions[productFilter]} />

                        </div>
                    </div>
                </div>

                <div className={styles.progressContainer}>

                    {progressData && progressDataFilter.map((data, index) => (

                        <div className={styles.progressItem} key={index} onClick={() => handleClickOpen(data)}>
                            <div style={{ padding: '16px' }}>
                                <div className={styles.titleContainer}>
                                    <div className={styles.progressTitle} >

                                        {data.brand_name}<img src={Arrow} />
                                    </div>
                                    <div className={styles.date}>
                                        {ConvertDate(data.latest_log_date, false, false)}
                                    </div>
                                </div>
                                <div className={styles.brandName}>
                                    <img src={BrandName} />
                                    <span>{data.team_name}</span>
                                </div>
                                <div className={styles.description}>
                                    <div className={styles.description1440}>
                                        <EllipsisText text={data.latest_log_description} maxLength={180} />
                                    </div>
                                    <div className={styles.description834}>
                                        <EllipsisText text={data.latest_log_description} maxLength={150} />
                                    </div>
                                </div>
                                <div className={styles.progressSpinet}>
                                    <LinearProgress variant="determinate" value={data.progress_percentage} className={data.progress_percentage == 100 ? styles.determinateComplete : styles.determinate} />
                                    {/* {data.progress_percentage && (
                                        <LinearProgress variant="determinate" value={data.progress_percentage} className={data.progress_percentage == 100 ? styles.determinateComplete : styles.determinate} />

                                    )}
                                    {!data.progress_percentage && (
                                        <LinearProgress variant="determinate" value={data.progress_percentage} className={data.progress_percentage == 100 ? styles.determinateComplete : styles.determinate} />

                                    )} */}
                                </div>
                                <div className={styles.footerProgress}>
                                    <div className={data.progress_percentage == 100 ? `${styles.statusComplete}` : styles.status}>{toTitleCase(data.status)}</div>
                                    <div className={styles.percent}>{data.progress_percentage}%</div>
                                </div>
                            </div>
                        </div>

                    ))}

                </div>

            </div>

            {isMobile == false ? (
                <WorkProgressDialogComponent
                    open={open}
                    onClose={handleClose}
                    workProgressDetail={dialogWorkProgress}
                />
            ) : (
                <WorkProgressDialogMobileComponent
                    open={open}
                    onClose={handleClose}
                    workProgressDetail={dialogWorkProgress}
                />
            )}
        </div>
    );

}
