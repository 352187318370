import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { useEffect, useState } from 'react';
import styles from './WorkProgressDialogComponent.module.css'
import './WorkProgressDialogComponent.css'
import Close from './../../Assets/Media/Close.svg';
import { WorkProgress } from '../../Types';
import BrandName from './../../Assets/Media/BrandName.svg';
import Link from './../../Assets/Media/Link.svg';
import LinearProgress from '@mui/material/LinearProgress';
import { toTitleCase } from '../WorkProgress/WorkProgressComponent';
import ConvertDate from '../Shared/ConvertDate';
import Clock from './../../Assets/Media/Clock.svg';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function extractHostname(url: string): string {
    if (!/^https?:\/\//i.test(url)) {
        url = 'http://' + url;
    }
    const hostname = new URL(url).hostname;
    return hostname.startsWith('www.') ? hostname.slice(4) : hostname;
}


type WorkProgressDialogComponentProps = {
    open: boolean;
    onClose: (value: boolean) => void;

    workProgressDetail: WorkProgress | null;
}

export const WorkProgressDialogComponent = ({ open, onClose, workProgressDetail }: WorkProgressDialogComponentProps) => {
    const [link, setLink] = useState<string>('#');
    useEffect(() => {
        if (workProgressDetail != null && workProgressDetail.project_link != null) {
            setLink(extractHostname(workProgressDetail.project_link));

        }
    })

    const [isOpen, setOpen] = React.useState(false);



    useEffect(() => {
        setOpen(open);
    }, [open]);

    const handleClose = () => {
        setOpen(false);
        onClose(false);
    };

    return (


        <Dialog
            open={isOpen}
            
            keepMounted
            onClose={handleClose}
            sx={{
                ".MuiDialog-paper": {
                    width: '424px',
                    minHeight: '366px',
                    height: 'auto',
                    backgroundColor: 'rgba(36, 36, 36, 1)',

                    // backdropFilter: 
                    borderRadius: '16px',
                    padding: '16px'
                }
            }}

        >
            <div style={{ padding: '8px' }}>
                <div className={styles.header} >
                    <div> {workProgressDetail?.brand_name} </div>
                    <img onClick={handleClose} src={Close} className={styles.closeButton} />
                </div>
                <div className={styles.brandName}>
                    <img src={BrandName} />

                    <span> {workProgressDetail?.team_name}</span>
                </div>
                <div className={styles.description}>
                    <div>{workProgressDetail?.latest_log_description}</div>
                </div>
                {workProgressDetail != null && workProgressDetail.project_link != null ? (
                    <a className={styles.link} target='blank' href={workProgressDetail.project_link}>
                        <img src={Link} style={{ marginRight: '5px' }} />
                        <span>{link}</span>
                    </a>
                ) : (
                    <div className={styles.linkNull} >

                    </div>
                )}
                <div className={styles.progressSpinet}>
                    <LinearProgress variant="determinate" value={workProgressDetail?.progress_percentage} className={workProgressDetail?.progress_percentage == 100 ? styles.determinateComplete : styles.determinate} />
                </div>
                <div className={styles.footer}>
                    <div className={styles.date}><img src={Clock} alt="" /> <span style={{ marginLeft: '5px' }}>{ConvertDate(workProgressDetail?.latest_log_date, false, true, false)}</span></div>
                    <div className={styles.leftFooter}>
                        {workProgressDetail != null ? (
                            <div className={workProgressDetail.progress_percentage == 100 ? `${styles.status} ${styles.statusComplete}` : styles.status}>{toTitleCase(workProgressDetail.status)} </div>
                        ) : (
                            <div className={styles.status}></div>
                        )}

                        <div className={styles.percent}>
                            {workProgressDetail?.progress_percentage}%
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>

    );
}