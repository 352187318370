import React from 'react';

interface Props {
    text: string;
    maxLength: number;
}

const EllipsisText: React.FC<Props> = ({ text, maxLength }) => {
    if (text != null) {
        const displayText = text.length > maxLength ? text.slice(0, maxLength) + '...' : text;

        return <span>{displayText}</span>;
    }
    return <span></span>;
};

export default EllipsisText;