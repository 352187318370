import { useEffect, useRef, useState } from "react";
import Arrow from './DropDownArrow.svg';
import styles from './DropDown.module.css';


export type DropDownOption = {
    value: string;
    label: string;
};

type DropDownProp = {
    defaultSelectedItem: DropDownOption|undefined;
    options: DropDownOption[];
    customClass: string;
    onSelect: (option:DropDownOption)=>void
}

export const DropDown = ({ options, customClass,onSelect, defaultSelectedItem }: DropDownProp) => {

    const [selectedOption, setSelectedOption] = useState<DropDownOption | undefined>(defaultSelectedItem);

    const selectItem = (item: DropDownOption) => {
        setSelectedOption(item);
        onSelect(item);
    }

    useEffect(()=>{
        setSelectedOption(defaultSelectedItem);
    },[defaultSelectedItem]);

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const ref = useRef<HTMLDivElement>(null);

    const toggleOpen = () => setIsOpen(!isOpen);

    useEffect(() => {
        const checkIfClickedOutside = (e: MouseEvent) => {
            if (isOpen && ref.current && !ref.current.contains(e.target as Node)) {
                setIsOpen(false);
            }
        };

        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [isOpen]);


    return (
        <>
            <div className={`${styles.comboBox} ${customClass}`} id='year' onClick={toggleOpen} ref={ref}>
                <div className={styles.selectTop}>
                    <span className={styles.selectedOption}>{selectedOption ? selectedOption.label : defaultSelectedItem!=undefined? defaultSelectedItem.label:''}</span>
                    <img className={styles.arrow} src={Arrow} alt="" />
                </div>
                {isOpen && (
                    <ul className={styles.optionsList}>
                        {options.map((option) => (
                            <li className={styles.option} key={option.value} onClick={() => selectItem(option)}>
                                {option.label}
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </>
    )

}