import React from 'react';


const ConvertDate = (date: string | undefined, isFull: boolean, isWithYear: boolean, withSuffix = true): string => {

    if (date == undefined || date == null) {
        return '';
    }

    const dateString = date;
    const dateObject = new Date(dateString);

    const shortMonthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    const fullMonthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const day = dateObject.getDate();

    const year = dateObject.getFullYear();

    let daySuffix = '';
    if (withSuffix == true) {
        switch (day) {
            case 1:
            case 21:
                daySuffix = "st";
                break;
            case 2:
            case 22:
                daySuffix = "nd";
                break;
            case 3:
            case 23:
                daySuffix = "rd";
                break;
            default:
                daySuffix = "th";
        }
    }


    const formattedDate = `${isFull == true ? fullMonthNames[dateObject.getMonth()] : shortMonthNames[dateObject.getMonth()]} ${day}${daySuffix}${isWithYear == true ? ' ,' + year : ''}`;

    return formattedDate;
}

export default ConvertDate;
