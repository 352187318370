import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import styles from './HeaderComponent.module.css';

import logo from './../../Assets/Media/Logo.svg';


type HeaderProps = {

}

export const HeaderComponent = () => {
    return (
        <div className={styles.header} >
            <div className={styles.HeaderFirst}>
                <div className={styles.headerLogo}>
                    <img className={styles.logo} src={logo} />
                </div>
            </div>
        </div>
    );
};